import React from 'react'
import { Button, Form } from 'react-bootstrap'
import styled from 'styled-components'
import LoginBanner from '../components/common/LoginBanner'
import { unauthNavTo } from '../components/navigation'
import Spinner from '../components/spinner'
import { useDoForgotPasswordMutation } from '../utils/api'

const OuterContainer = styled.div`
@media only screen and (max-width: 768px) {
  min-height: 500px;
}
@media only screen and (min-width: 767px) {
  height: 500px;
}
`

const isNonBlankString = s => typeof s === 'string' && s.trim().length > 0

// markup
const IndexPage = (props) => {
  // props
  const { location: { state = {} } = {} } = props
  const { emailAddress = '' } = state === null ? {} : state

  // state
  const [credentials, setCredentials] = React.useState({ emailAddress })
  const [errorMessage, setErrorMessage] = React.useState(null)

  const doForgotPasswordMutation = useDoForgotPasswordMutation()

  // navigate on success
  React.useEffect(() => {
    if (doForgotPasswordMutation.isSuccess) {
      setErrorMessage(null)
      unauthNavTo.forgotPasswordComplete(credentials)
    }
  }, [doForgotPasswordMutation.isSuccess, credentials])

  // show message on error
  React.useEffect(() => {
    if (doForgotPasswordMutation.isError) {
      setErrorMessage(doForgotPasswordMutation.error.message)
    }
  }, [doForgotPasswordMutation.isError, doForgotPasswordMutation.error])

  // form

  const handleSubmit = (event) => {
    event.preventDefault()
    event.stopPropagation()
    if (!isNonBlankString(credentials.emailAddress)) return setErrorMessage('Email address cannot be blank.')
    setErrorMessage(null)

    doForgotPasswordMutation.mutate(credentials)
  }

  return (
    <main className="main flex-fill" id="top">
      <div className="container-fluid">
        <div className="row min-vh-100 flex-center no-gutters">
          <div className="col-lg-8 col-xxl-5 py-3">
            <OuterContainer className="card overflow-hidden z-index-1">
              <div className="card-body p-0">
                <div className="row no-gutters h-100">
                  <LoginBanner showBackToLogin={true} />
                  <div className="col-md-7 d-flex flex-center">
                    <div className="px-4 px-md-5 d-flex flex-column flex-grow-1 my-5" style={{ minHeight: '300px' }}>
                      <h3 className="mb-3 font-weight-bold">Forgot your password?</h3>

                      <p>{'Enter the email address you registered your account with and we’ll send you a reset link.'}</p>

                      {/* form */}
                      <Form noValidate validated={false} onSubmit={handleSubmit} className="d-flex flex-column flex-grow-1 justify-content-between">

                        <div>
                          <Form.Group>
                            <Form.Label>Email address</Form.Label>
                            <Form.Control
                              type="email"
                              // placeholder="Email address"
                              autoComplete="emailAddress"
                              autoFocus={true}
                              value={credentials.emailAddress}
                              onChange={e => setCredentials(ps => ({ ...ps, emailAddress: e.target.value }))}
                              disabled={doForgotPasswordMutation.isLoading}
                              isInvalid={errorMessage !== null}
                            />
                            <Form.Control.Feedback type="invalid">{errorMessage}</Form.Control.Feedback>
                          </Form.Group>
                        </div>

                        <Form.Group className="mb-0">
                          <Button
                            className="btn-block"
                            type="submit"
                            // disabled={doForgotPasswordMutation.isLoading || credentials.emailAddress.length === 0 }
                            // onClick={(e) => doForgotPassword(e)}
                          >
                            { doForgotPasswordMutation.isLoading ? <Spinner /> : <span>Send Reset Link</span> }
                            </Button>
                        </Form.Group>

                      </Form>

                    </div>
                  </div>
                </div>
              </div>
            </OuterContainer>

            { process.env.NODE_ENV !== 'production' && <pre>credentials={JSON.stringify(credentials, null, 2)}</pre>}
          </div>
        </div>
      </div>
    </main>
  )
}

export default IndexPage
